import { Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import CustomContainer from "components/Atoms/Shared/CustomContainer";
import React from "react";
import { withTranslation } from "react-i18next";

const MissionAndVission = ({ t, missionAndvission }) => {
  const { lightBlue2, darkGray } = useThemePalette();
  return (
    <>
      <CustomContainer>
        <Stack
          direction={{ md: "row" }}
          justifyContent={"space-between"}
          sx={{ py: { md: "120px", xs: "60px" } }}
        >
          {missionAndvission?.map((data, i) => (
            <Stack key={i} width={{ md: "44%", sm: "70%", xs: "100%" }}>
              <Stack direction={"row"} columnGap={"20px"} mb="59px">
                <img src={data?.icon} />
                <Typography
                  color={lightBlue2}
                  sx={{ fontSize: { md: "60px", xs: "20px" }, fontWeight: 700 }}
                >
                  {data?.title}
                </Typography>
              </Stack>
              <Typography
                color={darkGray}
                sx={{ fontSize: { md: "24px", xs: "18px" }, fontWeight: 500 }}
              >
                {data?.description}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </CustomContainer>
    </>
  );
};

export default withTranslation()(MissionAndVission);
