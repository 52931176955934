import { Box, Button, Stack, Typography } from "@mui/material";
import { images } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import CustomContainer from "components/Atoms/Shared/CustomContainer";
import Description from "components/Atoms/Shared/Description";
import SectionTitle from "components/Atoms/Shared/SectionTitle";
import React from "react";
import { withTranslation } from "react-i18next";

const Header = ({ t }) => {
  const { primaryColor } = useThemePalette();
  return (
    <Stack sx={{ bgcolor: primaryColor, pt: "30px", pb: { md: 0, xs: "20px" } }}>
      <CustomContainer>
        <Stack direction={{ sm: "row" }} justifyContent={"space-between"}>
          <Stack width={{ md: "55%" }} rowGap={"20px"} justifyContent={"center"}>
            <SectionTitle component={"h1"} variant="h1" title={t("home.header.title")} />

            <Box>
              <Description
                description={t("home.header.description")}
                sx={{ width: { md: "80%" } }}
              />
            </Box>
            <Stack sx={{ display: { md: "flex", xs: "none" } }} direction={"row"} columnGap={2}>
              <Button href="" target="_blank">
                <img src={images.appleStore} height={"100%"} width={"100%"} alt="apple store" />
              </Button>
              <Button href="" target="_blank">
                <img src={images.googlePlay} width={"100%"} alt="google play" />
              </Button>
            </Stack>
          </Stack>
          {/* ----------------------------- Mobile image ------------------------------- */}
          <Stack
            width={{ sm: "35%" }}
            justifySelf={"end"}
            alignSelf={"flex-end"}
            justifyContent={"end"}
            sx={{ display: { md: "flex", xs: "none" } }}
          >
            <img src={images.headerPhoneImg} width={"100%"} alt="header img" />
          </Stack>
        </Stack>
      </CustomContainer>
    </Stack>
  );
};
export default withTranslation()(Header);
