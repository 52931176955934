import { createTheme } from "@mui/material";
import { lightBlue } from "@mui/material/colors";

export const theme = createTheme({
  typography: {
    fontFamily: ["Open Sans,sans-serif"].join(","),
    h1: {
      fontFamily: ["Open Sans,sans-serif"].join(","),
      fontSize: "40px",
      fontWeight: "600",
    },
  },
  button: {
    fontFamily: ["Open Sans,sans-serif"].join(","),
    borderRadius: "8px",
  },
  palette: {
    primary: {
      main: "#3FABAE",
    },
    secondary: {
      main: "##1A1A1A",
    },
    info: {
      main: "#FFFFFF",
    },
  },
  theme_palette: {
    primaryColor: "#3FABAE",
    secondaryColor: "#1A1A1A",
    white: "#fff",
    lightBlue: '#242a344d',
    lightBlue2: '#242a34',
    orange: "#F25500",
    lightGray: '#F5F5F5',
    darkGray: '#393939',
    midnightBlue: '#18191C',
    lightGray2: '#6B6B6B'
  },
  breakpoints: {
    values: {
      // xs: 0,
      // sm: 600,
      // md: 900,
      // lg: 1366,
      // xl: 1536,
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1445,
      xxl: 1536,
    },
  },
  overrides: {
    MuiPaper: {
      root: {},
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: '95% !important'
        },
        maxWidthLg: {
          maxWidth: '92% !important',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          "&:hover": {
            boxShadow: "none !important",
          },
        },
        contained: {},
        root: {
          "&.Mui-disabled": {},
          "&:hover": {},
          // borderRadius: '5px',
          // fontSize: '16px',
          // fontWeight: '600',
          textTransform: "none",
          minWidth: "auto",
          boxShadow: "none !important",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {},
        },
      },
    },
  },
});
