import { TextField, Typography, FormHelperText } from "@mui/material";
import "./TextFieldInput.css";
import { Grid } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
function TextFieldInput({
  type = "text",
  placeholder = "",
  multiline = false,
  rows,
  className,
  onChange,
  error,
  value,
  label,
  name,
  disabled,
  justifyContent,
  errorMessage,
  variant = "filled",
  required = true,
  arClassName,
  ...props
}) {
  const { lightBlue2, primaryColor } = useThemePalette();
  const lang = localStorage.getItem("i18nextLng");
  return (
    <Grid
      container
      sx={{ position: "relative", direction: lang == "ar" && "rtl" }}
      justifyContent={justifyContent}
      className={className}
    >
      {label && (
        <Typography className={arClassName} sx={{ pb: "16px" }} color={lightBlue2} fontWeight="600">
          {label}
          {required && (
            <Typography sx={{ color: primaryColor, pl: "5px" }} component="span">
              *
            </Typography>
          )}
        </Typography>
      )}

      <TextField
        className={arClassName}
        sx={{
          "& .MuiFilledInput-root:before": {
            border: 0,
            display: "none !important",
          },
          "& .MuiFilledInput-root:after": {
            border: 0,
            display: "none !important",
          },
          "& input::placeholder": {
            fontFamily: lang == "ar" && "Tajawal",
          },
          "& textarea::placeholder": {
            fontFamily: lang == "ar" && "Tajawal",
            margin: "20px !important",
          },

          "& input": {
            height: "100%  !important",
            bgcolor: "white",
            borderRadius: "10px",
          },
          "& textarea": {
            bgcolor: "white",
            borderRadius: "10px",
          },
          "& textarea.MuiFilledInput-input": {
            p: "10px",
          },
          "& .MuiFilledInput-root": {
            p: "0px",
          },
          // bgcolor: lightGray2,
          width: "100%", 
        }}
        id={name}
        hiddenLabel
        type={type}
        placeholder={placeholder}
        variant={variant}
        onChange={onChange}
        name={name}
        value={value}
        multiline={multiline}
        rows={rows}
        error={error}
        {...props}
      />
      {error && (
        <FormHelperText
          margin="0"
          sx={{
            color: "#ef5350",
            alignItems: "start",
            marginTop: "5px",
            marginLeft: "5px",
          }}
        >
          {errorMessage}
        </FormHelperText>
      )}
    </Grid>
  );
}

export default TextFieldInput;
