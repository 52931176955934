import { Paper, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useThemePalette } from "common/hooks/theme_palette";
import React from "react";

export default function PrivacyAndTermsSkleton({ pageTitle, children, subTitle }) {
  const { midnightBlue } = useThemePalette();
  return (
    <>
      <Container sx={{ my: "40px" }}>
        <Typography color={midnightBlue} fontSize="36px" fontWeight="700">
          {pageTitle}
        </Typography>
        {subTitle && (
          <Typography color={midnightBlue} fontSize="27px" fontWeight="700">
            {subTitle}
          </Typography>
        )}
        <Paper
          sx={{ p: "0 24px 0 0", mt: "26px", mb: "40px", boxShadow: "none", borderRadius: "0" }}
        >
          <Paper sx={{ p: "0 24px 0 0", boxShadow: "none" }}>{children}</Paper>
        </Paper>
      </Container>
    </>
  );
}
