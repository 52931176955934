import { Box, Stack, Typography } from "@mui/material";
import { images } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import CustomContainer from "components/Atoms/Shared/CustomContainer";
import Description from "components/Atoms/Shared/Description";
import SectionTitle from "components/Atoms/Shared/SectionTitle";
import React from "react";
import { withTranslation } from "react-i18next";

const Feature = ({ t, featureData }) => {
  const { secondaryColor, primaryColor, lightBlue } = useThemePalette();
  return (
    <>
      <CustomContainer>
        <Stack sx={{ py: { md: "120px", xs: "60px" } }} rowGap={"50px"}>
          {featureData?.map((feature, i) => (
            <Stack
              key={i}
              direction={{ md: i % 2 == 0 ? "row" : "row-reverse" }}
              justifyContent={{ md: "space-between", xs: "center" }}
              alignItems="center"
              rowGap={"20px"}
            >
              <Stack rowGap={4} sx={{ width: { md: "67%" } }}>
                <SectionTitle color={secondaryColor} title={t("home.feature.title")} />
                <Stack
                  sx={{ p: { md: "30px 20px 30px 30px", xs: "30px" }, borderRadius: "15px" }}
                  bgcolor={primaryColor}
                  direction={{ md: "row" }}
                  alignItems={{ md: "start", xs: "center" }}
                  justifyContent={{ md: "space-between", xs: "center" }}
                  width="100%"
                  minHeight={"350px"}
                >
                  <Stack sx={{ width: { md: "14%" } }}>
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        bgcolor: lightBlue,
                        width: { md: "120px", xs: "70px" },
                        height: { md: "120px", xs: "70px" },
                        borderRadius: "50%",
                      }}
                    >
                      <Box
                        width={feature.imgWidth || { md: "80px", xs: "50px" }}
                        height={feature.imgHeight || { md: "80px", xs: "50px" }}
                      >
                        <img src={feature.icon} width={"100%"} height={"100%"} alt="gift" />
                      </Box>
                    </Stack>
                  </Stack>
                  <Stack sx={{ width: { md: "82%" } }}>
                    <SectionTitle
                      sx={{ fontSize: { md: "40px", xs: "26px" } }}
                      title={feature?.title}
                    />
                    <Description
                      sx={{ fontSize: { lg: "22px", xs: "16px" } }}
                      description={feature.description}
                    />
                  </Stack>
                </Stack>
              </Stack>
              <Stack sx={{ width: { md: "25%", xs: "50%" } }}>
                <img src={feature.img} alt="featute img" width={"100%"} />
              </Stack>
            </Stack>
          ))}
        </Stack>
      </CustomContainer>
    </>
  );
};

export default withTranslation()(Feature);
