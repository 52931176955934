import { Typography } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";

const Description = ({ t, color = "white", description, ...rest }) => {
  return (
    <Typography {...rest} color={"white"} fontSize={{ lg: "26px", xs: "18px" }}>
      {description}
    </Typography>
  );
};

export default withTranslation()(Description);
