import { Routes, Route } from "react-router-dom";
import HomePage from "pages/home/Home.Page.jsx";
import { useState } from "react";
import Layout from "components/Organisms/layout";
import PrivacyPolicy from "pages/PrivacyPoclicy/PrivacyPolicy";
import TermsAndCodations from "pages/TermsAndConditions/TermsAndCodations";

const AppRoute = () => {
  // this state is for update breadcrumb data for each page
  const [layoutData, setLayoutData] = useState(null);
  return (
    <Routes>
      {/* admin layout */}
      <Route path="/" element={<Layout layoutData={layoutData} />}>
        <Route index element={<HomePage setLayoutData={setLayoutData} />} />
        <Route path="/terms-and-condations" element={<TermsAndCodations />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Route>
    </Routes>
  );
};

export default AppRoute;
