import { Box, Stack, Typography } from "@mui/material";
import { images } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import CustomContainer from "components/Atoms/Shared/CustomContainer";
import Description from "components/Atoms/Shared/Description";
import React from "react";
import { withTranslation } from "react-i18next";

const CadeauIdea = ({ t }) => {
  const { lightGray, orange, darkGray } = useThemePalette();
  return (
    <>
      <Stack bgcolor={lightGray} sx={{ py: { md: "80px", xs: "40px" } }}>
        <CustomContainer>
          <Stack
            direction={{ md: "row" }}
            alignItems={"center"}
            width={"100%"}
            justifyContent={"space-between"}
          >
            <Stack width={{ md: "27%", xs: "35%" }} sx={{ position: "relative" }}>
              <Box>
                <img src={images.ideatitleBg} width="100%" />
              </Box>
              <Stack
                sx={{
                  position: "absolute",
                  transform: "translate(-50%,  -50%)",
                  top: "50%",
                  left: "50%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xl: "55px", lg: "50px" },
                    color: "white",
                    fontWeight: 700,
                    textAlign: "center",
                  }}
                >
                  {t("home.cadeau_idea.title")}
                </Typography>
              </Stack>
            </Stack>
            <Stack width={{ md: "70%" }}>
              <Description
                sx={{ color: darkGray, fontWeight: "500", fontSize: "24px" }}
                description={t("home.cadeau_idea.description")}
              />
            </Stack>
          </Stack>
        </CustomContainer>
      </Stack>
    </>
  );
};

export default withTranslation()(CadeauIdea);
