import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import { Button, MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import i18n from "common/i18n";
import CssBaseline from "@mui/material/CssBaseline";

const Navbar = ({ t, navbarRef }) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [navbarContent, setNavbarContent] = useState(null);

  const { white, primaryColor, secondaryColor, red, blackTwo, lightGray2, lightGray } =
    useThemePalette();
  const [menuIcon, setMenuIcon] = useState(<MenuIcon sx={{ fontSize: "40px", color: "#000" }} />);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const pages = [
    { title: `${t("home.navbar.terms")}`, path: "/terms-and-condations" },
    { title: `${t("home.navbar.privacy")}`, path: "/privacy-policy" },
  ];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    setMenuIcon(
      <CloseIcon
        sx={{
          fontSize: "35px",
          color: "#000",
          cursor: "pointer",
          color: red,
          backgroundColor: lightGray2,
          borderRadius: "6px",
          p: "5px",
        }}
      />
    );
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    setMenuIcon(<MenuIcon sx={{ fontSize: "40px", color: "#000" }} />);
  };

  const handleOpenUserMenu = (e) => {
    setAnchorElUser(e.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleClick = (page) => {
    navigate(page.path);
    handleCloseNavMenu();
  };
  const checkUrl = () => {
    pathname == "erp";
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          bgcolor: "#fff !important",
          boxShadow: { md: "0px 2px 4px rgba(0, 0, 0, 0.16)", xs: "none" },
          py: "10px",
        }}
        component="nav"
        // ref={navbarRef}
      >
        <Box py="10px">
          <Toolbar style={{ paddingRight: 0, paddingLeft: 0 }}>
            <Container sx={{ maxWidth: { xl: "xl", md: "lg" } }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                {/* ------------------------------- Web LOGO -------------------------------- */}
                <Box
                  nowrap="true"
                  component="a"
                  onClick={(_) => {
                    navigate("/");
                    i18n.changeLanguage("en");
                  }}
                  sx={{
                    display: { md: "flex" },
                    color: "inherit",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  width="144px"
                >
                  <img src={icons.logo} width={"100%"} alt="logo" />
                </Box>
                <Box display={{ xs: "none", md: "flex" }}>
                  <Stack
                    flexDirection={{ xs: "column", md: "row" }}
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    columnGap={{ xl: "10px" }}
                  >
                    {pages?.map((page, i) => (
                      <Button
                        disableRipple
                        onClick={(e) =>
                          page.title == "Products" ? handleOpenUserMenu(e) : handleClick(page)
                        }
                        key={i}
                        variant={page?.title == "Contact us" ? "contained" : "text"}
                        sx={{
                          color:
                            page?.title == "Contact us"
                              ? "white"
                              : pathname == page?.path
                              ? primaryColor
                              : secondaryColor,
                          padding: { lg: "11px 20px", md: "11px 10px" },
                          fontWeight: 700,
                          fontSize: { lg: "18px", xs: "14px" },
                          ml: page?.title == "Contact us" && "30px",
                          "&:hover": {
                            backgroundColor: page?.title !== "Contact us" && "transparent",
                            color:
                              page?.title == "Contact us"
                                ? `${white} !important`
                                : `${red} !important`,
                          },
                        }}
                      >
                        {page.title}
                        {page.title == "Products" && <KeyboardArrowDownIcon />}
                      </Button>
                    ))}
                  </Stack>
                </Box>
                {/* ------------------------------- Responsive Menu Icon ---------------------- */}
                <Box sx={{ display: { xs: "flex", md: "none" } }}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                  >
                    {menuIcon}
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: "block", md: "none" },
                      "& .MuiPaper-root": {
                        width: { sm: "100%", xs: "95%" },
                        boxShadow: "none",
                        padding: "10px",
                      },
                    }}
                  >
                    <Stack
                      flexDirection={{ xs: "column", md: "row" }}
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      width="100%"
                      sx={{ borderTop: `1px solid ${lightGray}` }}
                    >
                      {pages?.map((page, i) => (
                        <Box
                          key={i}
                          sx={{
                            width: "100%",
                            // borderBottom: i !== pages?.length - 1 && "1px solid #c4c4c4",
                            p: 1,
                          }}
                        >
                          <Button
                            disableRipple
                            onClick={(e) => {
                              page.title == "Products" ? handleOpenUserMenu(e) : handleClick(page);
                            }}
                            key={i}
                            sx={{
                              color:
                                page?.title == "Contact us"
                                  ? "white"
                                  : pathname == page?.path
                                  ? primaryColor
                                  : secondaryColor,
                              padding: { lg: "11px 20px", md: "11px 10px", xs: "3px 15px" },
                              fontWeight: 700,
                              fontSize: { lg: "18px", xs: "14px" },
                              backgroundColor: page?.title == "Contact us" && red,
                              ml: { md: page?.title == "Contact us" && "74px", xs: "3px 10px" },
                              mt: {
                                md: page?.title == "Contact us" && "0",
                                xs: page?.title == "Contact us" && "10px",
                              },
                              marginInline: { xs: page?.title == "Contact us" && "10px" },
                              "&:hover": {
                                backgroundColor: page?.title !== "Contact us" ? "transparent" : red,
                                color:
                                  page?.title == "Contact us"
                                    ? `${white} !important`
                                    : `${red} !important`,
                              },
                            }}
                          >
                            {page.title}
                            {page.title == "Products" && <KeyboardArrowDownIcon />}
                          </Button>
                        </Box>
                      ))}
                    </Stack>
                  </Menu>
                </Box>
              </Stack>
            </Container>
          </Toolbar>
        </Box>
      </AppBar>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </Box>
  );
};
export default withTranslation()(Navbar);
