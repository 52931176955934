import { Typography } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";

const SectionTitle = ({ t, color = "white", title, ...rest }) => {
  return (
    <Typography
      {...rest}
      color={color}
      fontSize={{ lg: "70px", md: "40px", xs: "28px" }}
      fontWeight={700}
    >
      {title}
    </Typography>
  );
};

export default withTranslation()(SectionTitle);
