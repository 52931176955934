import Types from "./Types";

const INIT_STATE = {
  isOpen: false,
};

export default function modelReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.EXAMPLE_MODEL: {
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    }

    default: {
      return state;
    }
  }
}
