import React, { useRef } from "react";

import { ScrollToTop } from "components/Atoms/ScrollToTop/ScrollToTop";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar";

const Layout = ({ layoutData }) => {
  const navbarRef = useRef();
  return (
    <>
      <ScrollToTop />
      <Navbar navbarRef={navbarRef} />
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
