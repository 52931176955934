import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import Description from "./Description";
import SectionTitle from "./SectionTitle";
import { useThemePalette } from "common/hooks/theme_palette";
import { Navigate } from "react-router-dom";

export default function PrivacyAndTermsArticle({ description, title, my, data, urls }) {
  const { lightGray2, orange } = useThemePalette();
  return (
    <>
      <Box my={my} sx={{ width: "100%" }}>
        {title && (
          <SectionTitle sx={{ color: lightGray2, mb: "5px", fontSize: "22px" }} title={title} />
        )}{" "}
        {description && <Description description={description} />}
        {data &&
          data?.map((desc, i) => (
            <Box key={i}>
              {desc == "" ? (
                <br />
              ) : (
                <Description sx={{ color: lightGray2, fontSize: "18px" }} description={desc} />
              )}
            </Box>
          ))}
        {urls &&
          urls?.map((url, i) => (
            <Stack key={i} alignItems={"start"}>
              {url == "" ? (
                <br />
              ) : (
                <Button sx={{ color: orange, p: "10px 0" }} href={url.url} target="_blank">
                  {url.title}
                </Button>
              )}
            </Stack>
          ))}
      </Box>
    </>
  );
}
