import { icons, images } from "assets/AssetHelper";
import React from "react";
import { withTranslation } from "react-i18next";
import HomeTemplate from "templates/home/Home.Template";

const HomePage = ({ t, setLayoutData }) => {
  const featureData = [
    {
      title: t("home.feature.occassion_title"),
      description: t("home.feature.occassion_description"),
      icon: images.gift,
      img: images.feature,
    },
    {
      title: t("home.feature.style_title"),
      description: t("home.feature.style_description"),
      icon: images.gift,
      img: images.style,
    },
    {
      title: t("home.feature.delivered_title"),
      description: t("home.feature.delivered_description"),
      icon: images.location,
      img: images.delivered,
      imgWidth: "50px",
      imgHeight: "55px",
    },
  ];
  const missionAndvission = [
    {
      title: t("home.mission_and_vission.mission"),
      icon: icons.target,
      description: t("home.mission_and_vission.mission_description"),
    },
    {
      title: t("home.mission_and_vission.vision"),
      icon: icons.vission,
      description: t("home.mission_and_vission.vission_description"),
    },
  ];
  return <HomeTemplate featureData={featureData} missionAndvission={missionAndvission} />;
};

export default withTranslation()(HomePage);
