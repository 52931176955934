import logo from 'assets/icons/logo.svg'
import headerPhoneImg from 'assets/images/headerPhoneImg.png'
import googlePlay from 'assets/images/googlePlay.png'
import appleStore from 'assets/images/appleStore.png'
import feature from 'assets/images/feature.png'
import gift from 'assets/images/gift.png'
import location from 'assets/images/location.png'
import delivered from 'assets/images/delivered.png'
import style from 'assets/images/style.png'
import ideatitleBg from 'assets/images/ideatitleBg.png'
import target from 'assets/icons/target.svg'
import vission from 'assets/icons/vission.svg'
import email from 'assets/icons/email.svg'
import phone from 'assets/icons/phone.svg'


export const dummy = {

}

export const images = {
    headerPhoneImg,
    googlePlay,
    appleStore,
    feature,
    gift,
    location,
    delivered,
    style,
    ideatitleBg,
}

export const icons = {
    logo,
    target,
    vission,
    email,
    phone
}