import { Box, Button, Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
// import PhoneInput from "components/Atoms/Input/PhoneInput/PhoneInput";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import CustomContainer from "components/Atoms/Shared/CustomContainer";
import Description from "components/Atoms/Shared/Description";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { formDataRequest } from "services/modules/form";
import * as Yup from "yup";
import { resetMessage } from "services/modules/form/Actions";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import emailjs from "emailjs-com";

const phoneRegExp = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{5}$/;

const Footer = ({ t }) => {
  const dispatch = useDispatch();
  const { secondaryColor, lightBlue2 } = useThemePalette();
  const { pathname } = useLocation();
  const initialValues = {
    name: "",
    email: "",
    phone_number: "",
    subject: "",
    message: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Required!"),
    email: Yup.string().email("Invalid email format").required("Required!"),
    subject: Yup.string(),
    message: Yup.string(),
  });

  const sendEmail = (values) => {
    // setIsLoading(true);
    emailjs.send("service_ntohh0k", "template_nnsbx2o", values, "87c-fhHynznT03JXJ").then(
      (result) => {
        toast.success("form sent");
      },
      (error) => {
        toast.error("form failed");
      }
    );
  };

  const onSubmit = (values) => {
    sendEmail(values);
    formik?.resetForm();
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  useEffect(() => {
    formik?.resetForm();
  }, [pathname]);
  return (
    <>
      <Stack bgcolor={secondaryColor} sx={{ py: { md: "120px", xs: "60px" } }}>
        <CustomContainer>
          <Typography
            sx={{
              color: "white",
              fontSize: { md: "60px", sm: "30px", xs: "20px" },
              fontWeight: 700,
              mb: { md: "83px", xs: "30px" },
            }}
          >
            {t("home.contact_us.contact_us")}
          </Typography>
          <Stack direction={{ md: "row" }} justifyContent={{ md: "space-between", xs: "center" }}>
            <Stack width={{ lg: "30%", md: "28%" }} mb={{ md: 0, xs: "30px" }} rowGap={"20px"}>
              <Stack
                direction={"row"}
                columnGap={{ md: 0, xs: "20px" }}
                justifyContent={{ md: "space-between" }}
              >
                <img src={icons.phone} width="70px" height={"70px"} alt="phone icon" />
                <Stack width="80%">
                  <Description
                    sx={{ fontWeight: 700, mb: { md: "30px", xs: "10px" }, mt: "15px" }}
                    description={t("home.contact_us.phone")}
                  />
                  <Button
                    href="tel:+966530411026"
                    sx={{
                      color: "white",
                      width: "fit-content !important",
                      fontSize: "22px",
                      p: "0 !important",
                    }}
                  >
                    +966 53 041 1026
                  </Button>
                </Stack>
              </Stack>
              <Stack
                direction={"row"}
                columnGap={{ md: 0, xs: "20px" }}
                justifyContent={{ md: "space-between" }}
              >
                <img src={icons.email} width="70px" height={"70px"} alt="phone icon" />
                <Stack width="80%">
                  <Description
                    sx={{ fontWeight: 700, mb: { md: "30px", xs: "10px" }, mt: "15px" }}
                    description={t("home.contact_us.email")}
                  />
                  <Button
                    href="mailto:sales@cadeau.com.sa"
                    sx={{
                      color: "white",
                      width: "fit-content !important",
                      p: "0 !important",
                      fontSize: "22px",
                    }}
                  >
                    sales@cadeau.com.sa
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            <Stack width={{ md: "67%" }}>
              <form className="contact-form" onSubmit={formik?.handleSubmit}>
                <Box>
                  <Stack direction="row" justifyContent="space-between" flexWrap="wrap">
                    {/* --------------------------  NAME ------------------------------- */}
                    <Stack width={{ sm: "49%", xs: "100%" }} sx={{ pb: "16px" }}>
                      <TextFieldInput
                        placeholder="Name (required)"
                        onChange={formik?.handleChange}
                        name="name"
                        value={formik?.values?.name}
                        error={formik?.errors?.name && formik?.touched?.name}
                        errorMessage={formik?.errors?.name}
                        // label="NAME"
                      />
                    </Stack>
                    {/* ------------------------------ Email -------------------------- */}
                    <Stack width={{ sm: "49%", xs: "100%" }} sx={{ pb: "16px" }}>
                      <TextFieldInput
                        placeholder="Email (required)"
                        onChange={formik?.handleChange}
                        name="email"
                        value={formik?.values?.email}
                        error={formik?.errors?.email && formik?.touched?.email}
                        errorMessage={formik?.errors?.email}
                        // label="EMAIL"
                      />
                    </Stack>
                    {/* ------------------------------ Phone *-------------------------- */}
                    <Stack width={{ sm: "49%", xs: "100%" }} sx={{ pb: "16px" }}>
                      <TextFieldInput
                        placeholder="Phone"
                        onChange={formik?.handleChange}
                        name="phone_number"
                        value={formik?.values?.phone_number}
                        error={formik?.errors?.phone_number && formik?.touched?.phone_number}
                        errorMessage={formik?.errors?.phone_number}
                        // label="SUBJECT"
                      />
                    </Stack>
                    {/* ------------------------------ Subject *-------------------------- */}
                    <Stack width={{ sm: "49%", xs: "100%" }} sx={{ pb: "16px" }}>
                      <TextFieldInput
                        placeholder="Subject"
                        onChange={formik?.handleChange}
                        name="subject"
                        value={formik?.values?.subject}
                        error={formik?.errors?.subject && formik?.touched?.subject}
                        errorMessage={formik?.errors?.subject}
                        // label="SUBJECT"
                      />
                    </Stack>
                    {/* ------------------------------ Message *-------------------------- */}
                    <Stack width={"100%"} sx={{ pb: "16px" }}>
                      <TextFieldInput
                        placeholder="Message"
                        onChange={formik?.handleChange}
                        name="message"
                        value={formik?.values?.message}
                        error={formik?.errors?.message && formik?.touched?.message}
                        errorMessage={formik?.errors?.message}
                        // label="MESSAGE"
                        multiline
                        rows={5}
                      />
                    </Stack>
                  </Stack>
                  <Stack direction={{ md: "row" }} justifyContent={"flex-end"} columnGap={"50px"}>
                    <Button
                      variant="outlined"
                      sx={{
                        px: { md: "40px", xs: "30px" },
                        my: "16px",
                        py: "10px",
                        width: "fit-content",
                        fontSize: "20px",
                        fontWeight: "600",
                        border: "1px solid white",
                        color: "white",
                        width: { md: "auto", xs: "100%" },
                      }}
                      onClick={(_) => formik.resetForm()}
                    >
                      Clear
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        px: { md: "40px", xs: "30px" },
                        my: "16px",
                        py: "10px",
                        width: "fit-content",
                        fontSize: "20px",
                        fontWeight: "600",
                        bgcolor: "white",
                        width: { md: "auto", xs: "100%" },

                        "&:hover": {
                          bgcolor: "white",
                        },
                      }}
                    >
                      SEND
                    </Button>
                  </Stack>
                </Box>
              </form>
            </Stack>
          </Stack>
        </CustomContainer>
      </Stack>
      <Stack
        justifyContent={"center"}
        gap="4px"
        direction={"row"}
        sx={{ py: "15px" }}
        alignItems={"center"}
      >
        <CopyrightIcon />
        <Typography>2021 Dollarchat All Rights Reserved.</Typography>
      </Stack>
    </>
  );
};

export default withTranslation()(Footer);
