import CadeauIdea from "components/Organisms/home/cadeauIdea";
import Feature from "components/Organisms/home/feature";
import Header from "components/Organisms/home/header";
import MissionAndVission from "components/Organisms/home/missionAndVission";

function HomeTemplate({ featureData,missionAndvission }) {
  return (
    <>
      <Header />
      <Feature featureData={featureData} />
      <CadeauIdea />
      <MissionAndVission  missionAndvission={missionAndvission}/>
    </>
  );
}

export default HomeTemplate;
